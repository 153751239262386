import React, { useEffect, useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../buttons/Button';
import { formatCurrency, getDigitCertRequest } from 'core/utils';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import CurrencyFormat from 'react-currency-format';
import { AssetStockItem } from 'core/types';
import { SellStockConfirmModal } from './SellStockConfirmModal';
import { toast } from 'react-toastify';
import { useAccount, useLoading } from 'core/contexts';
import { MarketStockService } from 'core';
import { checkSavePin } from 'core/utils';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import { formatStockPrice } from 'core/utils';
import { GENERAL_SERVER_ERROR } from 'core/constant';
import { AccountService } from 'core';
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
const { XMLParser } = require('fast-xml-parser');

type ModalProps = {
  isOpen: boolean;
  resetData: () => void;
  closeModal: () => void;
  stocks: AssetStockItem[];
};

const myWindow = window as any;

export const SellStocksModal = ({ isOpen, stocks, closeModal, resetData }: ModalProps) => {
  const { isLoading, showLoading, hideLoading } = useLoading();
  const { account, userInfo } = useAccount();
  const [isShowConfirmOrder, setIsShowConfirmOrder] = useState(false);
  const [factorAuthenType, setFactorAuthenType] = useState<
    'PIN' | 'SMART_OTP' | 'SMS_OTP' | 'DIGIT_CERT'
  >();
  const [certInfo, setCertInfo] = useState<any>();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid },
  } = useForm<{
    stocks: {
      name: string;
      floor: number;
      ceiling: number;
      price: number;
      available: number;
      quantity: number;
    }[];
  }>({
    mode: 'onChange',
  });
  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'stocks', // unique name for your Field Array
  });

  if (isOpen && errors?.stocks?.[0]) {
    toast(errors?.stocks[0]?.quantity?.message || errors?.stocks[0]?.price?.message, {
      type: 'error',
      icon: <RiErrorWarningLine className="text-[#F04438]" />,
    });
  }

  const innit = async () => {
    const initData: any[] = [];
    for (let i = 0; i < stocks.length; i++) {
      if (stocks[i].stock_symbol) {
        const res = await MarketStockService.getStockInfo(stocks[i].stock_symbol);
        const data = res?.data?.data;
        const q =
          stocks[i].available_quantity <= 100
            ? stocks[i].available_quantity
            : Math.floor(stocks[i].available_quantity / 100) * 100;
        initData.push({
          name: data?.stock_code,
          floor: data?.floor / 1000,
          ceiling: data?.ceiling / 1000,
          lot_size: data?.lot_size,
          price: data?.floor / 1000,
          available: stocks[i].available_quantity,
          quantity: q,
        });
      }
    }
    setValue('stocks', initData);
  };

  const onError = () => {};

  useEffect(() => {
    if (isOpen) {
      innit();
    } else {
      reset();
    }
  }, [isOpen]);

  const getSettings = async () => {
    const res = await AccountService.getSettings('TwoFactorAuthentication');
    const type = res?.data.data?.value;
    if (!type) {
      toast('Không thể lấy phương thức xác thực', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return;
    }
    setFactorAuthenType(type);
    setIsShowConfirmOrder(true);
  };

  const onSubmit = async (data: any) => {
    await getSettings();
  };

  const handleSellStock = async (paramsdata: any, isFailed: boolean) => {
    try {
      const res = await MarketStockService.sellStock(userInfo?.user_id || '', paramsdata);
      const data = res?.data;
      if (data?.status === 'Fail') {
        isFailed = true;
      }
      if (data?.error_code === 'PinNotMatch' || data?.error_code === 'PinVerifyLocked') {
        toast(data?.error_message.split('|')[1], {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />,
        });
        hideLoading();
        return;
      }
      if (data?.error_code === 'Exception') {
        toast(data?.error_message, {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />,
        });
        hideLoading();
        return;
      }
    } catch {
      toast(GENERAL_SERVER_ERROR, {
        type: 'error',
        icon: <RiErrorWarningLine className="text-error" />,
      });
    }
  };

  const submitOrder = async (pin: string, isCheckSavePinCode?: boolean) => {
    showLoading();
    const values = getValues()?.stocks;
    let isFailed = false;
    for (let i = 0; i < values.length; i++) {
      let paramsdata: any = {
        account_no: account?.account_id,
        matching_type: 'LO',
        stock_symbol: values[i]?.name,
        price: Math.floor(values[i]?.price * 1000),
        quantity: values[i]?.quantity,
        note: '',
        pin: pin,
        factor_authen_enum_type: factorAuthenType,
      };
      if (pin && (factorAuthenType === 'SMART_OTP' || factorAuthenType === 'SMS_OTP')) {
        paramsdata.otp = pin;
        paramsdata.pin = '';
      }

      if (factorAuthenType === 'DIGIT_CERT') {
        const data = {
          account_no: account?.account_id,
          matching_type: 'LO',
          stock_symbol: values[i]?.name,
          price: Math.floor(values[i]?.price * 1000),
          quantity: values[i]?.quantity,
          note: '',
        };

        const digit_cert_request = await getDigitCertRequest(data, certInfo);
        paramsdata = {
          ...paramsdata,
          digit_cert_request,
        };

        // const handleSignCMS = (event: any) => {
        //   signedData = event.detail;
        //   paramsdata = {
        //     ...paramsdata,
        //     digit_cert_request: {
        //       original_data: JSON.stringify(data),
        //       signed_data: signedData ? JSON.parse(signedData)?.data : '',
        //       cert_base64: certInfo?.base64,
        //       signature_base64: '',
        //       serial_id: certInfo?.serial,
        //       subject_cn: certInfo?.subjectCN,
        //       issuer_cn: certInfo?.issuerCN,
        //       not_before: dayjs(certInfo?.notBefore).add(7, 'hours').toISOString(),
        //       not_after: dayjs(certInfo?.notAfter).add(7, 'hours').toISOString(),
        //       tax_number: certInfo?.taxNumber,
        //       personal_id: certInfo?.personalId || '',
        //       passport: certInfo?.passport,
        //     },
        //   };

        //   handleSellStock(paramsdata, isFailed);
        //   window.removeEventListener('SignCMSCompleted', handleSignCMS);
        // };

        // window.addEventListener('SignCMSCompleted', handleSignCMS);

        // try {
        //   await myWindow?.signCMS(JSON.stringify(data));
        // } catch (error) {
        //   console.log('SignCMS Error:', error);
        // }
      }

      await handleSellStock(paramsdata, isFailed);

      if (isFailed) {
        toast(
          'Có ít nhất một lệnh trong những lệnh bạn đặt gặp lỗi. Vui lòng kiểm tra sổ lệnh để tham khảo lệnh đặt thành công và thử lại.',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-error" />,
          }
        );
      } else {
        toast(
          'Yêu cầu đặt lệnh đã được tiếp nhận. Quý khách vui lòng kiểm tra trạng thái lệnh trong sổ lệnh.',
          {
            type: 'success',
            icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
          }
        );
      }
      if (isCheckSavePinCode && factorAuthenType === 'PIN') {
        await checkSavePin(pin);
      }
      hideLoading();
      resetData();
      closeModal();
      setIsShowConfirmOrder(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        hasCloseBtn
        closeBtnClassName="text-[#667085]"
        title="Xác nhận giao dịch bán"
        onClose={closeModal}
        className={`max-w-[640px]`}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <p className="text-sm">Quý khách có xác nhận đặt lệnh bán các mã sau?</p>
            <div className="mt-6">
              <div className="flex gap-4 lg:gap-12 items-center py-3 text-sm text-text_main_light">
                <div className="flex-1">Mã</div>
                <div className="w-[100px] text-right">Khối lượng</div>
                <div className="w-[100px] text-right">Giá bán</div>
              </div>
              {fields.map((field: any, index) => {
                return (
                  <div
                    key={field.id}
                    className="flex gap-4 lg:gap-12 items-center border-t border-[#1D2939] py-3"
                  >
                    <div className="flex-1">
                      <div>{field?.name}</div>
                      <div className="flex items-center gap-[2px]">
                        <span className="text-sm text-ceilingPriceColor">
                          {formatStockPrice(field.ceiling * 1000)}
                        </span>
                        <span className="text-[10px] text-text_main_light">|</span>
                        <span className="text-sm text-floorPriceColor">
                          {formatStockPrice(field.floor * 1000)}
                        </span>
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name={`stocks.${index}.quantity`}
                      rules={{
                        required: 'Vui lòng nhập khối lượng',
                        min: {
                          value: 0,
                          message: 'Khối lượng phải lớn hơn 0',
                        },
                        max: {
                          value: field?.available,
                          message: `Khối lượng phải nhỏ hơn hoặc bằng ${formatCurrency(
                            field?.available
                          )}`,
                        },
                        validate: value => {
                          if (value > 100 && value % field?.lot_size !== 0) {
                            return 'Khối lượng đặt của lệnh lô lẻ từ 1 đến 99';
                          }
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <CurrencyFormat
                          className={`bg-[#0C111D] w-[100px] px-2 py-3 text-sm text-right rounded-[6px] border border-[#1D2939] focus:outline-none ${
                            errors.stocks?.[index]?.quantity && 'form-input__input_error text-error'
                          }`}
                          value={value}
                          onValueChange={(v: any) => {
                            const floatValue = v?.floatValue || '';
                            onChange(floatValue);
                          }}
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`stocks.${index}.price`}
                      rules={{
                        required: 'Vui lòng nhập giá',
                        min: {
                          value: field?.floor,
                          message: `Giá đặt phải nhỏ hơn hoặc bằng ${field?.floor}`,
                        },
                        max: {
                          value: field?.ceiling,
                          message: `Giá đặt phải nhỏ hơn hoặc bằng ${field?.ceiling}`,
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <CurrencyFormat
                          className={`bg-[#0C111D] w-[100px] px-2 py-3 text-sm text-right rounded-[6px] border border-[#1D2939] focus:outline-none ${
                            errors.stocks?.[index]?.price && 'form-input__input_error text-error'
                          }`}
                          value={value}
                          onValueChange={(v: any) => {
                            const floatValue = v?.floatValue || '';
                            onChange(floatValue);
                            onError();
                          }}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                        />
                      )}
                    />
                  </div>
                );
              })}
            </div>
            <div className="flex items-center gap-3 mt-10">
              <Button
                className="flex-1"
                type="button"
                variant="secondary"
                onClick={() => closeModal()}
              >
                Huỷ
              </Button>
              <Button
                disabled={!isValid || isLoading}
                variant="primary"
                className="flex-1"
                type="submit"
              >
                Xác nhận
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      <SellStockConfirmModal
        isOpen={isShowConfirmOrder}
        factorAuthenType={factorAuthenType}
        certInfo={certInfo}
        title="Xác nhận đặt lệnh"
        stocks={getValues()?.stocks}
        handleGetCertInfo={data => {
          setCertInfo(data);
        }}
        closeModal={() => setIsShowConfirmOrder(false)}
        onSubmit={pin => submitOrder(pin, true)}
      />
    </>
  );
};
